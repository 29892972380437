<!-- 明细信息 -->
<template>
  <div class="main">
    <el-table
      v-loading="loading"
      :data="goodsData"
      show-summary
      :summary-method="getSummaries"
      border
      :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
      :row-style="{'font-size': '14px', 'color': '#0D0202'}"
      :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
    >
      <el-table-column
        prop="supplyNo"
        label="订货单"
        width="200"
        fixed
      >
        <el-table-column
          prop="supplyNo"
          label="总计"
          width="200"
        />
      </el-table-column>
      <el-table-column
        prop="goodsId"
        label="商品ID"
        fixed
        width="80"
      >
        <el-table-column
          prop="goodsId"
          width="80"
        />
      </el-table-column>
      <el-table-column
        prop="goodsName"
        label="商品名称"
        fixed
        width="120"
      >
        <el-table-column
          prop="goodsName"
          width="120"
        />
      </el-table-column>
      <el-table-column
        prop="skuName"
        label="商品规格"
        width="120"
        fixed
      >
        <el-table-column
          prop="skuName"
          width="120"
        />
      </el-table-column>
      <el-table-column
        prop="purchasePrice"
        label="订货价（元）"
        width="120"
      >
        <el-table-column
          prop="purchasePrice"
          width="120"
        />
      </el-table-column>
      <el-table-column
        prop="purchaseCount"
        label="订货数"
      >
        <el-table-column
          prop="purchaseCount"
          :label="$route.query.purchaseCount"
        />
      </el-table-column>
      <el-table-column
        prop="purchaseAmount"
        width="120"
        label="订货金额（元）"
      >
        <el-table-column
          prop="purchaseAmount"
          width="120"
          :label="$route.query.purchaseAmount"
        />
      </el-table-column>
      <el-table-column
        prop="refundNo"
        label="订货退货单"
        width="100"
      >
        <el-table-column
          prop="refundNo"
          width="100"
        />
      </el-table-column>
      <el-table-column
        prop="refundCount"
        label="订货退货数"
        width="100"
      >
        <el-table-column
          prop="refundCount"
          width="100"
          :label="$route.query.refundCount"
        />
      </el-table-column>
      <el-table-column
        prop="refundAmount"
        label="订货退货金额（元）"
        width="160"
      >
        <el-table-column
          prop="refundAmount"
          width="160"
          :label="$route.query.refundAmount"
        />
      </el-table-column>
      <el-table-column
        prop="actuallyCount"
        width="100"
        label="实际订货数"
      >
        <el-table-column
          prop="actuallyCount"
          width="100"
          :label="$route.query.actuallyCount"
        />
      </el-table-column>
      <el-table-column
        prop="actuallyAmount"
        label="实际订货金额（元）"
        width="160"
      >
        <el-table-column
          prop="actuallyAmount"
          width="160"
          :label="$route.query.actuallyAmount"
        />
      </el-table-column>
      <el-table-column
        label="订货对账数"
        width="150"
      >
        <el-table-column
          prop="checkCount"
          width="150"
          :label="$route.query.checkCount"
        >
          <template slot-scope="{row}">
            <el-input
              v-if="isMemberStore && $route.query.type === 'edit'
                && (Number($route.query.auditStatus) === 0
                  || Number($route.query.auditStatus) === -1)"
              v-model="row.checkCount"
              placeholder="请输入内容"
              @change="changeCheckCount(row)"
            />
            <span v-else>{{ row.checkCount }}</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="checkAmount"
        label="订货对账金额（元）"
        width="150"
      >
        <el-table-column
          prop="checkAmount"
          width="150"
          :label="$route.query.checkAmount"
        >
          <template slot-scope="{row}">
            <el-input
              v-if="isMemberStore && $route.query.type === 'edit'
                && (Number($route.query.auditStatus) === 0
                  || Number($route.query.auditStatus) === -1)"
              v-model="row.checkAmount"
              placeholder="请输入内容"
              @change="changeCheckAmount(row)"
            />
            <span v-else>{{ row.checkAmount }}</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="remarks"
        label="备注"
        width="300"
      >
        <el-table-column width="300">
          <template slot-scope="{row}">
            <el-input
              v-if="isMemberStore && $route.query.type === 'edit'
                && (Number($route.query.auditStatus) === 0
                  || Number($route.query.auditStatus) === -1)"
              v-model="row.remarks"
              placeholder="请输入内容"
              maxlength="15"
              show-word-limit
              @change="changeRemarks(row)"
            />
            <span v-else>{{ row.remarks }}</span>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script setup>
import {
  ref, onMounted, getCurrentInstance, computed,
} from 'vue';
// import dayjs from 'dayjs';

const app = getCurrentInstance().proxy;
const { $route } = app;
const goodsData = ref([]); // 明细信息
const currentPage = ref(1); // 分页
const pageSize = ref(10);
const count = ref(0);
const loading = ref(false);
const props = defineProps({
  changeData: {
    type: Array,
    default: () => [],
  },
});
// 暂存已经改动的数据
const { isMemberStore } = JSON.parse(localStorage.getItem('userInfo'));
// 计算属性简写方式(计算属性不可被修改)
const orderId = computed(() => {
  return $route.query.supplySettlementId;
});

const getDetail = async () => {
  loading.value = true;
  const res = await app.$axios.post(app.$api.supplierSettlement.detail, {
    currentPage: currentPage.value,
    pageSize: pageSize.value,
    supplySettlementId: orderId.value,
  });
  goodsData.value = res.data.records;
  count.value = Number(res.data.total);
  loading.value = false;
};

const handleSizeChange = (val) => {
  pageSize.value = val;
  currentPage.value = 1;
  getDetail();
};

const handleCurrentChange = (val) => {
  currentPage.value = val;
  getDetail();
};
const getSummaries = (param) => {
  const { columns, data } = param;
  const sums = [];
  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = '当前页小计';
      return;
    }
    // 总的展示字段
    let dataKey = [
      'purchaseCount',
      'purchaseAmount',
      'refundCount',
      'refundAmount',
      'actuallyCount',
      'actuallyAmount',
      'checkCount',
      'checkAmount',
    ];
    // 不需要保留两位小数的字段
    let noDecimal = ['purchaseCount', 'refundCount', 'actuallyCount', 'checkCount'];
    if (dataKey.includes(column.property)) {
      const values = data.map((item) => Number(item[column.property]));
      if (!values.every((value) => Number.isNaN(value))) {
        sums[index] = values.reduce((prev, curr) => {
          const value = Number(curr);
          if (!Number.isNaN(value)) {
            // let parsedNumber = parseFloat(prev + curr);
            return prev + curr;
          }
          return prev;
        }, 0);
        // 转换保留两个小数
        if (!noDecimal.includes(column.property)) {
          sums[index] = sums[index].toFixed(2);
        }
        sums[index] += '';
      } else {
        sums[index] = '-';
      }
    }
  });

  return sums;
};
// 订货对账数
const changeCheckCount = (row) => {
  const index = props.changeData.findIndex((item) => item.supplySettlementGoodsId
  === row.supplySettlementGoodsId);
  if (index === -1) {
    props.changeData.push({
      supplySettlementGoodsId: row.supplySettlementGoodsId,
      checkCount: row.checkCount,
      checkAmount: row.checkAmount,
      remarks: row.remarks,
    });
  } else {
    props.changeData[index].checkCount = row.checkCount;
    props.changeData[index].checkAmount = row.checkAmount;
    props.changeData[index].remarks = row.remarks;
  }
};
// 订货对账金额
const changeCheckAmount = (row) => {
  const index = props.changeData.findIndex((item) => item.supplySettlementGoodsId
  === row.supplySettlementGoodsId);
  if (index === -1) {
    props.changeData.push({
      supplySettlementGoodsId: row.supplySettlementGoodsId,
      checkAmount: row.checkAmount,
      checkCount: row.checkCount,
      remarks: row.remarks,
    });
  } else {
    props.changeData[index].checkCount = row.checkCount;
    props.changeData[index].checkAmount = row.checkAmount;
    props.changeData[index].remarks = row.remarks;
  }
};
// 备注
const changeRemarks = (row) => {
  const index = props.changeData.findIndex((item) => item.supplySettlementGoodsId
  === row.supplySettlementGoodsId);
  if (index === -1) {
    props.changeData.push({
      supplySettlementGoodsId: row.supplySettlementGoodsId,
      checkAmount: row.checkAmount,
      checkCount: row.checkCount,
      remarks: row.remarks,
    });
  } else {
    props.changeData[index].checkCount = row.checkCount;
    props.changeData[index].checkAmount = row.checkAmount;
    props.changeData[index].remarks = row.remarks;
  }
};

onMounted(() => {
  getDetail();
});
</script>

<style lang="scss" scoped>
.main {
  background: #fff;
  padding: 20px;
  .back {
    margin-top: 20px;
  }
  .table-card{
    :deep(.el-tabs__item){
      font-size: 18px;
    }
  }
  :deep(.el-table__body-wrapper){
    z-index: 1;
  }
}
.header {
  border-left: 3px solid #409EFF;
  padding: 12px;
  font-size: 18px;
  margin: 20px 0;
  .tip {
    font-size: 12px;
    color: #999
  }
  .title {
    display: inline-block;
    margin-right: 50px;
  }
}
.detail-form {
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
  td {
    padding: 12px 16px;
  }
}
.countdownWrap{
  position:relative;
}
.countdown{
  color:red;
  position:absolute;
  top:12px;
  white-space: nowrap;
}
.order-tips {
  font-size: 14px;
  border: 1px solid #ebeef5;
  padding: 20px;
  text-align: center;
}
.goods{
  display: flex;
  img {
    width: 70px;
    height: 70px;
  }
  p {
    padding: 0 12px;
  }
  .id {
    color: #736E6E;
  }
}
.remark {
  padding: 12px 8px;
  border: 1px solid #dfe6ec;
  margin-bottom: 10px;
}
</style>
