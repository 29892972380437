.
<template>
  <div class="main">
    <el-table
      :data="goodsData"
      border
      :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
      :row-style="{'font-size': '14px', 'color': '#0D0202'}"
      :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
    >
      <el-table-column
        prop="operatorTypeName"
        label="操作类型"
        width="160"
      />
      <el-table-column
        prop="operator"
        label="操作员"
        width="160"
      />
      <el-table-column
        prop="content"
        label="执行内容"
      />
      <el-table-column
        label="操作时间"
        width="200"
      >
        <template slot-scope="{row}">
          <span>{{ formatTime(row.createTime) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script setup>
import {
  ref, onMounted, getCurrentInstance, computed,
} from 'vue';
import dayjs from 'dayjs';

const app = getCurrentInstance().proxy;
const { $route } = app;
const goodsData = ref([]); // 明细信息
const currentPage = ref(1); // 分页
const pageSize = ref(10);
const count = ref(0);
const loading = ref(false);
// 计算属性简写方式(计算属性不可被修改)
const orderId = computed(() => {
  return $route.query.supplySettlementId;
});
const formatTime = (time) => {
  return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
};

const getDetail = async () => {
  loading.value = true;
  const res = await app.$axios.post(app.$api.supplierSettlement.log, {
    currentPage: currentPage.value,
    pageSize: pageSize.value,
    supplySettlementId: orderId.value,
  });
  goodsData.value = res.data.records;
  count.value = Number(res.data.total);
  loading.value = false;
};

const handleSizeChange = (val) => {
  pageSize.value = val;
  currentPage.value = 1;
  getDetail();
};

const handleCurrentChange = (val) => {
  currentPage.value = val;
  getDetail();
};

onMounted(() => {
  getDetail();
});
</script>

<style lang="scss" scoped>
.main {
  background: #fff;
  padding: 20px;
  .back {
    margin-top: 20px;
  }
  .table-card{
    :deep(.el-tabs__item){
      font-size: 18px;
    }
  }
}
.header {
  border-left: 3px solid #409EFF;
  padding: 12px;
  font-size: 18px;
  margin: 20px 0;
  .tip {
    font-size: 12px;
    color: #999
  }
  .title {
    display: inline-block;
    margin-right: 50px;
  }
}
.detail-form {
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
  td {
    padding: 12px 16px;
  }
}
.countdownWrap{
  position:relative;
}
.countdown{
  color:red;
  position:absolute;
  top:12px;
  white-space: nowrap;
}
.order-tips {
  font-size: 14px;
  border: 1px solid #ebeef5;
  padding: 20px;
  text-align: center;
}
.goods{
  display: flex;
  img {
    width: 70px;
    height: 70px;
  }
  p {
    padding: 0 12px;
  }
  .id {
    color: #736E6E;
  }
}
.remark {
  padding: 12px 8px;
  border: 1px solid #dfe6ec;
  margin-bottom: 10px;
}
</style>
