var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _setup.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _setup.goodsData,
            "show-summary": "",
            "summary-method": _setup.getSummaries,
            border: "",
            "header-row-style": { "font-weight": 100, color: "#0D0202" },
            "row-style": { "font-size": "14px", color: "#0D0202" },
            "header-cell-style": {
              "font-weight": "bold",
              background: "#F0F2F8",
            },
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "supplyNo",
                label: "订货单",
                width: "200",
                fixed: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "supplyNo", label: "总计", width: "200" },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "goodsId",
                label: "商品ID",
                fixed: "",
                width: "80",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "goodsId", width: "80" },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "goodsName",
                label: "商品名称",
                fixed: "",
                width: "120",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "goodsName", width: "120" },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "skuName",
                label: "商品规格",
                width: "120",
                fixed: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "skuName", width: "120" },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "purchasePrice",
                label: "订货价（元）",
                width: "120",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "purchasePrice", width: "120" },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { prop: "purchaseCount", label: "订货数" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "purchaseCount",
                  label: _setup.$route.query.purchaseCount,
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "purchaseAmount",
                width: "120",
                label: "订货金额（元）",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "purchaseAmount",
                  width: "120",
                  label: _setup.$route.query.purchaseAmount,
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { prop: "refundNo", label: "订货退货单", width: "100" } },
            [
              _c("el-table-column", {
                attrs: { prop: "refundNo", width: "100" },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: { prop: "refundCount", label: "订货退货数", width: "100" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "refundCount",
                  width: "100",
                  label: _setup.$route.query.refundCount,
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "refundAmount",
                label: "订货退货金额（元）",
                width: "160",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "refundAmount",
                  width: "160",
                  label: _setup.$route.query.refundAmount,
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "actuallyCount",
                width: "100",
                label: "实际订货数",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "actuallyCount",
                  width: "100",
                  label: _setup.$route.query.actuallyCount,
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "actuallyAmount",
                label: "实际订货金额（元）",
                width: "160",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "actuallyAmount",
                  width: "160",
                  label: _setup.$route.query.actuallyAmount,
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { label: "订货对账数", width: "150" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "checkCount",
                  width: "150",
                  label: _setup.$route.query.checkCount,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _setup.isMemberStore &&
                        _setup.$route.query.type === "edit" &&
                        (Number(_setup.$route.query.auditStatus) === 0 ||
                          Number(_setup.$route.query.auditStatus) === -1)
                          ? _c("el-input", {
                              attrs: { placeholder: "请输入内容" },
                              on: {
                                change: function ($event) {
                                  return _setup.changeCheckCount(row)
                                },
                              },
                              model: {
                                value: row.checkCount,
                                callback: function ($$v) {
                                  _vm.$set(row, "checkCount", $$v)
                                },
                                expression: "row.checkCount",
                              },
                            })
                          : _c("span", [_vm._v(_vm._s(row.checkCount))]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "checkAmount",
                label: "订货对账金额（元）",
                width: "150",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "checkAmount",
                  width: "150",
                  label: _setup.$route.query.checkAmount,
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _setup.isMemberStore &&
                        _setup.$route.query.type === "edit" &&
                        (Number(_setup.$route.query.auditStatus) === 0 ||
                          Number(_setup.$route.query.auditStatus) === -1)
                          ? _c("el-input", {
                              attrs: { placeholder: "请输入内容" },
                              on: {
                                change: function ($event) {
                                  return _setup.changeCheckAmount(row)
                                },
                              },
                              model: {
                                value: row.checkAmount,
                                callback: function ($$v) {
                                  _vm.$set(row, "checkAmount", $$v)
                                },
                                expression: "row.checkAmount",
                              },
                            })
                          : _c("span", [_vm._v(_vm._s(row.checkAmount))]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { prop: "remarks", label: "备注", width: "300" } },
            [
              _c("el-table-column", {
                attrs: { width: "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _setup.isMemberStore &&
                        _setup.$route.query.type === "edit" &&
                        (Number(_setup.$route.query.auditStatus) === 0 ||
                          Number(_setup.$route.query.auditStatus) === -1)
                          ? _c("el-input", {
                              attrs: {
                                placeholder: "请输入内容",
                                maxlength: "15",
                                "show-word-limit": "",
                              },
                              on: {
                                change: function ($event) {
                                  return _setup.changeRemarks(row)
                                },
                              },
                              model: {
                                value: row.remarks,
                                callback: function ($$v) {
                                  _vm.$set(row, "remarks", $$v)
                                },
                                expression: "row.remarks",
                              },
                            })
                          : _c("span", [_vm._v(_vm._s(row.remarks))]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _setup.currentPage,
          "page-sizes": [10, 20, 30, 50],
          "page-size": _setup.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _setup.count,
        },
        on: {
          "size-change": _setup.handleSizeChange,
          "current-change": _setup.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }