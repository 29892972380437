var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _setup.goodsData,
            border: "",
            "header-row-style": { "font-weight": 100, color: "#0D0202" },
            "row-style": { "font-size": "14px", color: "#0D0202" },
            "header-cell-style": {
              "font-weight": "bold",
              background: "#F0F2F8",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "operatorTypeName",
              label: "操作类型",
              width: "160",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "operator", label: "操作员", width: "160" },
          }),
          _c("el-table-column", {
            attrs: { prop: "content", label: "执行内容" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作时间", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_setup.formatTime(row.createTime))),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _setup.currentPage,
          "page-sizes": [10, 20, 30, 50],
          "page-size": _setup.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _setup.count,
        },
        on: {
          "size-change": _setup.handleSizeChange,
          "current-change": _setup.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }