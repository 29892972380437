var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("p", { staticClass: "header" }, [_vm._v(" 单据信息 ")]),
      _c("table", { staticClass: "detail-form" }, [
        _c("tr", [
          _c("td", { staticClass: "countdownWrap" }, [
            _c("span", [
              _vm._v(
                "对账单状态：" + _vm._s(_setup.$route.query.auditStatusName)
              ),
            ]),
          ]),
          _c("td", { staticClass: "countdownWrap" }, [
            _c("span", [
              _vm._v(
                "原因说明：" +
                  _vm._s(_setup.$route.query.auditDescription || "-")
              ),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "countdownWrap" }, [
            _c("span", [
              _vm._v(
                "订货对账单名称：" +
                  _vm._s(_setup.$route.query.settlementInterval)
              ),
            ]),
          ]),
          _c("td", [
            _vm._v(
              "创建时间：" +
                _vm._s(
                  _setup
                    .dayjs(Number(_setup.$route.query.createTime))
                    .format("YYYY-MM-DD HH:mm:ss")
                )
            ),
          ]),
        ]),
      ]),
      _c(
        "el-tabs",
        {
          staticClass: "table-card",
          on: { "tab-click": _setup.handleClick },
          model: {
            value: _setup.activeName,
            callback: function ($$v) {
              _setup.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "明细信息", name: "first" } },
            [
              _c(_setup.detailsInfo, {
                ref: "detailsInfos",
                attrs: { "change-data": _setup.changeData },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "商品信息", name: "second" } },
            [_c(_setup.goodsDetail)],
            1
          ),
          _setup.isMemberStore
            ? _c(
                "el-tab-pane",
                { attrs: { label: "操作日志", name: "three" } },
                [_c(_setup.logDetail)],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "back",
          on: {
            click: function ($event) {
              return _setup.$router.go(-1)
            },
          },
        },
        [_vm._v(" 返 回 ")]
      ),
      !_setup.isMemberStore &&
      _setup.$route.query.type === "edit" &&
      Number(_setup.$route.query.auditStatus) === 1
        ? _c(
            "el-button",
            {
              staticClass: "back",
              attrs: { type: "primary" },
              on: { click: _setup.examine },
            },
            [_vm._v(" 审核 ")]
          )
        : _vm._e(),
      _setup.isMemberStore &&
      _setup.$route.query.type === "edit" &&
      (Number(_setup.$route.query.auditStatus) === 0 ||
        Number(_setup.$route.query.auditStatus) === -1)
        ? _c(
            "el-button",
            {
              staticClass: "back",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _setup.submit()
                },
              },
            },
            [_vm._v(" 保存 ")]
          )
        : _vm._e(),
      _setup.isMemberStore &&
      _setup.$route.query.type === "edit" &&
      (Number(_setup.$route.query.auditStatus) === 0 ||
        Number(_setup.$route.query.auditStatus) === -1)
        ? _c(
            "el-button",
            {
              staticClass: "back",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _setup.submit(1)
                },
              },
            },
            [_vm._v(" 保存并提交 ")]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订货退货审核",
            visible: _setup.dialogFormVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _setup.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _setup.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审核结果" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _setup.form.state,
                        callback: function ($$v) {
                          _vm.$set(_setup.form, "state", $$v)
                        },
                        expression: "form.state",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v(" 同意 "),
                      ]),
                      _c("el-radio", { attrs: { label: -1 } }, [
                        _vm._v(" 拒绝 "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _setup.form.state === -1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "原因说明" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "50",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _setup.form.reason,
                          callback: function ($$v) {
                            _vm.$set(_setup.form, "reason", $$v)
                          },
                          expression: "form.reason",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _setup.examineClose } }, [
                _vm._v(" 取 消 "),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _setup.examineConfirm },
                },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }