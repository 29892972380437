.
<template>
  <div class="main">
    <p class="header">
      单据信息
    </p>
    <table class="detail-form">
      <tr>
        <td class="countdownWrap">
          <span>对账单状态：{{ $route.query.auditStatusName }}</span>
        </td>
        <td class="countdownWrap">
          <span>原因说明：{{ $route.query.auditDescription || '-' }}</span>
        </td>
      </tr>
      <tr>
        <td class="countdownWrap">
          <span>订货对账单名称：{{ $route.query.settlementInterval }}</span>
        </td>
        <td>创建时间：{{ dayjs(Number($route.query.createTime)).format('YYYY-MM-DD HH:mm:ss') }}</td>
        <!-- dayjs($route.query.createTime).format('YYYY-MM-DD HH:mm:ss' -->
      </tr>
    </table>
    <el-tabs
      v-model="activeName"
      class="table-card"
      @tab-click="handleClick"
    >
      <el-tab-pane
        label="明细信息"
        name="first"
      >
        <detailsInfo
          ref="detailsInfos"
          :change-data="changeData"
        />
      </el-tab-pane>
      <el-tab-pane
        label="商品信息"
        name="second"
      >
        <goodsDetail />
      </el-tab-pane>
      <el-tab-pane
        v-if="isMemberStore"
        label="操作日志"
        name="three"
      >
        <logDetail />
      </el-tab-pane>
    </el-tabs>
    <el-button
      class="back"
      @click="$router.go(-1)"
    >
      返 回
    </el-button>
    <el-button
      v-if="!isMemberStore && $route.query.type === 'edit'
        && Number($route.query.auditStatus) === 1"
      class="back"
      type="primary"
      @click="examine"
    >
      审核
    </el-button>
    <el-button
      v-if="isMemberStore && $route.query.type === 'edit'
        && (Number($route.query.auditStatus) === 0 || Number($route.query.auditStatus) === -1)"
      class="back"
      type="primary"
      @click="submit()"
    >
      保存
    </el-button>
    <el-button
      v-if="isMemberStore && $route.query.type === 'edit'
        && (Number($route.query.auditStatus) === 0 || Number($route.query.auditStatus) === -1)"
      class="back"
      type="primary"
      @click="submit(1)"
    >
      保存并提交
    </el-button>

    <el-dialog
      title="订货退货审核"
      :visible.sync="dialogFormVisible"
      width="30%"
    >
      <el-form :model="form">
        <el-form-item label="审核结果">
          <el-radio-group v-model="form.state">
            <el-radio :label="2">
              同意
            </el-radio>
            <el-radio :label="-1">
              拒绝
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="form.state === -1"
          label="原因说明"
        >
          <el-input
            v-model="form.reason"
            type="textarea"
            maxlength="50"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="examineClose">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="examineConfirm"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  ref, onMounted, getCurrentInstance,
} from 'vue';
import dayjs from 'dayjs';
import detailsInfo from './components/detailsInfo.vue';
import goodsDetail from './components/goodsDetail.vue';
import logDetail from './components/logDetail.vue';

const app = getCurrentInstance().proxy;
const { $route, $router } = app;
const dialogFormVisible = ref(false);
const changeData = ref([]);
const form = ref({
  state: 2, // 审核结果
  reason: '', // 原因说明
});
const activeName = ref('first');
const { isMemberStore } = JSON.parse(localStorage.getItem('userInfo'));
// 计算属性简写方式(计算属性不可被修改)
// const orderId = computed(() => {
//   return $route.query.orderId;
// });

const handleClick = (tab, event) => {
  console.log(tab, event);
};

const examine = () => {
  dialogFormVisible.value = true;
};
// 审核取消
const examineClose = () => {
  dialogFormVisible.value = false;
  form.value = {
    state: '', // 审核结果
    reason: '', // 原因说明
  };
};
// 审核确认
const examineConfirm = () => {
  // 拒绝时必须填写原因
  if (form.value.state === -1 && !form.value.reason) {
    app.$message.error('请填写原因');
    return;
  }
  let userInfo = JSON.parse(localStorage.getItem('userInfo'));
  let params = {
    supplySettlementId: app.$route.query.supplySettlementId,
    auditStatus: form.value.state,
    operator: userInfo?.userRealName ? userInfo?.userRealName : userInfo?.userMobile,
    auditDescription: form.value.reason,
  };
  app.$axios
    .post(app.$api.supplierSettlement.audit, params)
    .then(() => {
      app.$message.success('审核成功');
      dialogFormVisible.value = false;
      form.value = {
        state: '', // 审核结果
        reason: '', // 原因说明
      };
      $router.go(-1);
    })
    .catch((err) => {
      console.log(err);
    });
};

const submit = (type) => {
  let userInfo = JSON.parse(localStorage.getItem('userInfo'));
  let params = {
    supplySettlementId: app.$route.query.supplySettlementId,
    settlementGoodsEditParamList: app.$refs.detailsInfos.changeData,
    operator: userInfo?.userRealName ? userInfo?.userRealName : userInfo?.userMobile,
    isCommit: type,
  };
  app.$axios
    .post(app.$api.supplierSettlement.edit, params)
    .then(() => {
      app.$message.success('提交成功');
      $router.go(-1);
    })
    .catch((err) => {
      console.log(err);
    });
};

onMounted(() => {
  // getDetail();
});
</script>

<style lang="scss" scoped>
.main {
  background: #fff;
  padding: 20px;
  .back {
    margin-top: 20px;
  }
  .table-card{
    :deep(.el-tabs__item){
      font-size: 18px;
    }
  }
}
.header {
  border-left: 3px solid #409EFF;
  padding: 12px;
  font-size: 18px;
  margin: 20px 0;
  .tip {
    font-size: 12px;
    color: #999
  }
  .title {
    display: inline-block;
    margin-right: 50px;
  }
}
.detail-form {
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
  td {
    padding: 12px 16px;
  }
}
.countdownWrap{
  position:relative;
}
.countdown{
  color:red;
  position:absolute;
  top:12px;
  white-space: nowrap;
}
.order-tips {
  font-size: 14px;
  border: 1px solid #ebeef5;
  padding: 20px;
  text-align: center;
}
.goods{
  display: flex;
  img {
    width: 70px;
    height: 70px;
  }
  p {
    padding: 0 12px;
  }
  .id {
    color: #736E6E;
  }
}
.remark {
  padding: 12px 8px;
  border: 1px solid #dfe6ec;
  margin-bottom: 10px;
}
</style>
