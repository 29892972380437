var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _setup.goodsData,
            "show-summary": "",
            "summary-method": _setup.getSummaries,
            border: "",
            "header-row-style": { "font-weight": 100, color: "#0D0202" },
            "row-style": { "font-size": "14px", color: "#0D0202" },
            "header-cell-style": {
              "font-weight": "bold",
              background: "#F0F2F8",
            },
          },
        },
        [
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "goodsId",
                label: "商品ID",
                width: "100",
                fixed: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "goodsId", label: "总计", width: "100" },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "goodsName",
                label: "商品名称",
                width: "120",
                fixed: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "goodsName", width: "120" },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "skuName",
                label: "商品规格",
                width: "120",
                fixed: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "skuName", width: "120" },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { prop: "purchaseCount", label: "订货数" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "purchaseCount",
                  label: _setup.$route.query.purchaseCount,
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "purchaseAmount",
                label: "订货金额（元）",
                width: "150",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "purchaseAmount",
                  width: "150",
                  label: _setup.$route.query.purchaseAmount,
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: { prop: "refundCount", width: "150", label: "订货退货数" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  width: "150",
                  prop: "refundCount",
                  label: _setup.$route.query.refundCount,
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "refundAmount",
                label: "订货退货金额（元）",
                width: "150",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "refundAmount",
                  width: "150",
                  label: _setup.$route.query.refundAmount,
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "actuallyCount",
                label: "实际订货数",
                width: "100",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "actuallyCount",
                  width: "100",
                  label: _setup.$route.query.actuallyCount,
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "actuallyAmount",
                label: "实际订货金额（元）",
                width: "150",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "actuallyAmount",
                  width: "150",
                  label: _setup.$route.query.actuallyAmount,
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: { prop: "checkCount", label: "订货对账数", width: "100" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "checkCount",
                  width: "100",
                  label: _setup.$route.query.checkCount,
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            {
              attrs: {
                prop: "checkAmount",
                label: "订货对账金额（元）",
                width: "150",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "checkAmount",
                  width: "150",
                  label: _setup.$route.query.checkAmount,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _setup.currentPage,
          "page-sizes": [10, 20, 30, 50],
          "page-size": _setup.pageSize,
          layout: "total, sizes, prev, pager, next, jumper",
          total: _setup.count,
        },
        on: {
          "size-change": _setup.handleSizeChange,
          "current-change": _setup.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }